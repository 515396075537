<template>
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3">
        <v-row>
          <v-col cols="12">
            <site-header
              backRouteName="portfolio"
              backText="portfolios"
              title="portfolio Details"
              sub-title="write down the event"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <v-container>
      <main class="pb-10">
        <div>
          <list class="h-100 wa__large" :class="{ 'loading-form': getDataLoading }">
            <loading v-if="getDataLoading" class="loading-data" />
            <div class="pb-16 pt-12">
              <div class="portfolio__detail__image">
                <div>
                  <img
                    :src="portfolioDetails.thumbnail"
                    class="rounded w-100"
                    alt="portfolio detail image"
                  />
                </div>
                <div v-if="galleries.length" class="mt-4">
                  <div class="portfolio__detail--small-image d-flex align-center">
                    <img
                      v-for="(image, index) in portfolioDetails.galleries"
                      :key="image.id"
                      :src="image.thumbnail"
                      @click="showImage(index)"
                      class="rounded w-100 mr-1"
                      alt="portfolio small picture"
                    />
                    <light-box
                      :media="galleries"
                      :showLightBox="false"
                      ref="lightbox"
                    ></light-box>
                  </div>
                </div>
                <div class="name__box mt-5">
                  <div class="portfolio__detail__name wa__f__m__eb">
                    {{ portfolioDetails.title }}
                  </div>
                  <div class="portfolio__detail__schedule">
                    <small class="mr-8">{{ portfolioDetails.date }}</small>
                    <span>{{ portfolioDetails.time }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-10 portfolio__detail--detail">
                <SectionTitle title="summary" />
                <div class="pr-10 mt-3">
                  {{ portfolioDetails.summary }}
                </div>
                <div class="mt-4">
                  <v-chip
                    v-for="(tag, index) in portfolioDetails.tags"
                    :key="index"
                    text-color="black"
                    color="#e6e6e675"
                    label
                    class="chip__tag mr-1"
                    small
                  >
                    {{ tag }}
                  </v-chip>
                </div>
                <div class="portfolio__detail__image mt-16">
                  <video
                    width="100%"
                    height="100%"
                    controls
                    :poster="portfolioDetails.thumbnail"
                  >
                    <source
                      v-if="!getDataLoading"
                      :src="portfolioDetails.video_link"
                      type="video/mp4"
                    />
                    Your browser does not support HTML video.
                  </video>
                </div>
              </div>
              <div v-if="portfolioDetails.users_object && portfolioDetails.users_object.length" class="mt-16 portfolio__detail__model__tagged">
                <SectionTitle title="models tagged" />
                <div class="d-flex justify-start items mt-3">
                  <div
                    v-for="model in portfolioDetails.users_object"
                    :key="model.id"
                    class="pr-sm-3 mt-2 mt-sm-0 item"
                  >
                    <div>
                      <router-link
                        :to="{
                          name: 'modelDetailPortfolio',
                          params: { id: model.id, locale: $_getlocaleParam() },
                        }"
                        class="text-decoration-none"
                      >
                        <div>
                          <picture>
                            <img
                              data-async-image="true"
                              :src="model.model.thumbnail"
                              class="model__image w-100"
                              decoding="async"
                          /></picture>
                        </div>
                        <!--  -->
                        <div class="model__title black--text text-uppercase mt-2">
                          {{ model.name }}
                        </div>
                        <div class="model__sub-title black--text">
                          {{ model.country.name }}
                          {{ model.model.height }} / {{ model.model.weight }} /
                          {{ model.model.bust }} / {{ model.model.waist }} /
                          {{ model.model.hips }}
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-16" v-if="Object.keys(portfolioDetails.project).length">
                <SectionTitle title="project" />
                <v-row class="mt-3 mx-0 mb-0">
                  <v-col cols="12">
                    <v-row class="border rounded portfolio__project--item">
                      <v-col cols="4">
                        <div class="d-flex align-center mt-n1 h-100">
                          <div class="d-grid">
                            <small class="wa__model__status--hired pl-3">HIRED BY:</small>
                            <div class="wa__model__status--text">
                              <div class="wa__model__status--title pl-3">
                                {{
                                  portfolioDetails.project.user
                                    ? portfolioDetails.project.user.name
                                    : ""
                                }}
                              </div>

                              <div class="wa__model__status--operations pl-3">
                                <!-- <block-button
                                  height="12"
                                  class="btn-operations btn__size__8"
                                  :text="portfolioDetails.project.busyStatus"
                                  color="purple"
                                  bg-color="bgPurple"
                                ></block-button> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div class="d-flex align-center justify-center h-100">
                          <div class="timeline__button">
                            <v-btn
                              x-small
                              color="grey-lighten-3"
                              class="btn__size__12 px-6 w-100 justify-start"
                              depressed
                            >
                              FROM. &nbsp;{{ portfolioDetails.project.begin_date }}
                            </v-btn>
                            <div>
                              <v-btn
                                x-small
                                color="grey-lighten-3"
                                class="btn__size__12 px-6 w-100 justify-start"
                                depressed
                              >
                                <span class="mr-8">TO.</span>
                                {{ portfolioDetails.project.end_date }}
                              </v-btn>
                            </div>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div class="d-flex align-center justify-end pr-5 h-100">
                          <div class="line_align">
                            <div>
                              <strong class="project-fee-price d-block text-left"
                                >${{
                                  portfolioDetails.project.fee
                                    ? portfolioDetails.project.fee.toLocaleString("de-DE")
                                    : ""
                                }}</strong
                              >
                            </div>
                            <small class="project-fee">PROJECT FEE</small>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </div>
          </list>
        </div>
      </main>
    </v-container>
    <footer>
      <div class="wa__home--footer">
        <site-footer text="all right reserved"></site-footer>
      </div>
    </footer>
  </div>
</template>
<script>
const DETAILS_DEFAULT = {
  project: {
    user: {},
  },
  galleries: [],
};

import { cloneDeep } from "lodash";
import SectionTitle from "@/components/Global/Section/SectionTitle.vue";
import PortfolioRepository from "@/abstraction/repository/portfolio/portfolioRepository";
const portfolioRepository = new PortfolioRepository();
import LightBox from "vue-image-lightbox";
export default {
  components: {
    SectionTitle,
    LightBox,
  },
  data: () => ({
    portfolioDetails: cloneDeep(DETAILS_DEFAULT),
    getDataLoading: false,
    galleries: [],
  }),
  props: {},
  methods: {
    async loadPortfolioDetails() {
      try {
        this.getDataLoading = true;
        this.portfolioDetails = await portfolioRepository.show(this.$route.params.id);
        this.galleries = this.portfolioDetails.galleries
          ? this.portfolioDetails.galleries.map((x) => {
              return {
                thumb: x.thumbnail,
                src: x.image,
                type: 'jpg',
              };
            })
          : [];
      } catch (error) {
        return error;
      } finally {
        this.getDataLoading = false;
      }
    },
    showImage(index) {
      console.log(this.$refs.lightbox[0], index);
      this.$refs.lightbox.showImage(index);
    },
  },
  created() {
    this.loadPortfolioDetails();
  },
};
</script>
<style scoped>
/* container */
.container {
  max-width: 1640px;
}

.portfolio__detail__image {
  width: 640px;
  /* height: 360px; */
  position: relative;
  margin: 0 auto;
}
.name__box {
  line-height: 36px;
}
.portfolio__detail__name {
  font-family: "Montserrat-regular";
  font-size: 35px;
  color: var(--color-black);
}
.portfolio__detail__schedule {
  font-family: "Montserrat-Light";
  font-size: 25px;
}
.inner {
  width: 584px;
  margin: 0 auto;
}
.portfolio__detail--detail {
  font-family: "Montserrat-Light";
}
.portfolio__detail--detail .chip__tag {
  font-family: "Montserrat-regular";
  font-size: 18px;
}
.portfolio__detail--small-image {
  width: 85px;
}
.model__title {
  font-family: "Montserrat-regular" !important;
  font-size: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}
.model__sub-title {
  font-family: "Montserrat-light" !important;
  color: var(--color-gray);
  position: relative;
  top: -0.3rem;
  font-size: 12px;
}
.items {
  flex-wrap: wrap;
}
.items .item {
  width: 16.6666666667%;
  margin-bottom: 20px;
}
/* project */
.portfolio__project--item {
  font-family: "Montserrat-light";
}
.wa__model__status--hired {
  font-size: 10px;
}
.wa__model__status--text {
  line-height: 15px;
}
.wa__model__status--title {
  font-family: "Montserrat-regular";
  font-size: 16px;
  text-transform: uppercase;
}
.line_align {
  line-height: 15px;
}
.project-fee-price {
  font-family: "Montserrat-bold";
  font-size: 22px;
}
.project-fee {
  font-size: 10px;
  letter-spacing: 2px;
}
@media (max-width: 1200px) {
  .items .item {
    width: 20%;
    margin-bottom: 20px;
  }
  .items .item:nth-child(6n) {
    padding-right: 12px !important;
  }
}
@media (max-width: 960px) {
  .items .item {
    width: 25%;
    margin-bottom: 20px;
  }
  .items .item:nth-child(5n) {
    padding-right: 12px !important;
  }
}
@media (max-width: 768px) {
  .items .item {
    width: 33.33333%;
    margin-bottom: 20px;
  }

  .items .item:nth-child(4n) {
    padding-right: 12px !important;
  }
}
@media (max-width: 480px) {
  .items {
    justify-content: center;
  }
  .items .item {
    width: 50%;
    margin-bottom: 10px;
    padding: 10px !important;
  }
  .items .item:nth-child(3n),
  .items .item:nth-child(4n),
  .items .item:nth-child(5n),
  .items .item:nth-child(6n) {
    padding-right: 0 !important;
  }
}

.loading-data {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
}

.loading-form {
  filter: blur(5px);
}
@media screen and (max-width: 1264px) {
  .loading-data {
    position: absolute;
    left: 50%;
    z-index: 9999;
    transform: translateX(-50%);
    top: 50px;
  }
}

@media screen and (max-width: 960px) {
  .loading-data {
    position: absolute;
    left: 50%;
    z-index: 9999;
    transform: translateX(-50%);
    top: 50px;
  }
}

@media screen and (max-width: 600px) {
  .loading-data {
    position: absolute;
    left: 50%;
    z-index: 9999;
    transform: translateX(-50%);
    top: 50px;
  }
}
</style>
